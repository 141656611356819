import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { ConfirmDelete, Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { SchoolModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
    codeName = '';
    name = '';
    cid = '';
    active = '';
}

type response = { [key: string]: unknown };

@Component<AdminSchoolIndexController>({
    components: {
        SideNav, ConfirmDelete, Lightbox
    }
})
export default class AdminSchoolIndexController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private cityList: object = {};
    private schoolList: object = {};
    private num = 0;
    private pageHtml = "";
    private sid = 0;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo!: number;

    public async created() {
        this.cityList = await Common.getAllCity(); //取得所有縣市
        this.getSchoolList();
    }

    public async getSchoolList() {
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            codeName: this.searchForm.codeName,
            name: this.searchForm.name,
            cid: this.searchForm.cid,
            active: this.searchForm.active,
            token: this.token,
        }
        const data = await SchoolModel.getList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.schoolList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }

    private delSchool(id: number) {
        this.$emit("openConfirmDelete", true);
        this.sid = id;
    }

    private async deleteSchool() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                sid: this.sid,
                token: this.token,
            }
            this.errNo = await SchoolModel.del(item);
            this.$emit("closeConfirmDelete", false);
            Common.checkToken(this.errNo);
            this.errorMsg = ErrorMessage[this.errNo];
            this.openLB = true;
            this.doubleClick = false;
        }
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.go(0);
        }
    }
}
